import { io } from "socket.io-client";

declare global {
  interface Window {
    socket: typeof socket;
  }
}

const socket = io("https://party-io-example.jevakallio.partykit.dev", {
  // const socket = io("http://localhost:1999", {
  transports: ["websocket"],
});

socket.on("hello", (arg) => {
  console.log("hello", arg);
});

socket.on("pong", (arg) => {
  console.log("pong", arg);
});

socket.emit("howdy", "stranger");

socket.on("whoops", (arg) => {
  console.log("whoops!!", arg);
});

socket.on("bc", (arg) => {
  console.log("bc!!", arg);
});

socket.on("disconnect", (reason) => {
  console.log("disconnected", reason);
});
